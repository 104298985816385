import React, { useState } from "react";
import Navbar from "../components/Navbar";
import { Form, Button, Alert } from "react-bootstrap";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";

const UserForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};

    if (!name.trim()) {
      newErrors.name = "Name is required";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || !emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }

    const phoneRegex = /^\d{10}$/;
    if (!phone || !phoneRegex.test(phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number";
    }

    if (!termsChecked) {
      newErrors.terms = "You must accept the Terms & Conditions";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      navigate("/mobile/quiz");
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <div id="user-form">
      <Navbar />
      <div className="tall-margin d-flex align-items-center justify-content-center">
        <img src="/assets/logo.gif" className="w-50" alt="Logo" />
      </div>
      <Form className="p-3" onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Control
            type="text"
            placeholder="NAME"
            value={name}
            onChange={(e) => setName(e.target.value)}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type="email"
            placeholder="EMAIL"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        {/* <Form.Group className="mb-3" controlId="formBasicPhone">
          <Form.Control
            type="text"
            placeholder="PHONE"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            isInvalid={!!errors.phone}
          />
          <Form.Control.Feedback type="invalid">
            {errors.phone}
          </Form.Control.Feedback>
        </Form.Group> */}

        <Form.Group className="mb-3 d-flex" controlId="formBasicTC">
          <Form.Check
            type="checkbox"
            className="me-2"
            checked={termsChecked}
            onChange={(e) => setTermsChecked(e.target.checked)}
            isInvalid={!!errors.terms}
          />
          <Form.Label>TERMS & CONDITIONS</Form.Label>
        </Form.Group>
        {errors.terms && (
          <Alert variant="danger" className="py-1">
            {errors.terms}
          </Alert>
        )}

        <div className="text-center">
          <Button
            type="submit"
            className="btn btn-primary bg-red border-0 px-5 py-2"
          >
            START QUIZ
          </Button>
        </div>
      </Form>

      <Footer />
    </div>
  );
};

export default UserForm;
