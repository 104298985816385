import React from "react";
import { Row } from "react-bootstrap";

const Navbar = ({ absolute = false, monitor = false }) => {
  return (
    <nav
      id="navbar"
      className={`z-1 bg-red ${monitor && "p-3"} ${
        absolute ? "position-absolute top-0 start-0 w-100" : null
      }`}
    >
      <div className="d-flex align-items-center justify-content-center">
        <img
          width={monitor ? "150px" : "70px"}
          className="mx-2"
          src="/assets/logo.png"
        />
      </div>
    </nav>
  );
};

export default Navbar;
