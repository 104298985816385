import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";

const Instructions = () => {
  return (
    <div id="instructions">
      <Navbar />

      <Container className="mt-4">
        <h1 className="text-center text-uppercase">
          How to complete the{" "}
          <span className="red-highlight">UTGO experience</span>
        </h1>

        <div id="instructions-container" className="mt-4">
          <div className="instruction-step">
            <div className="red-circle">1</div>
            GO TO&nbsp;
            <a className="link" href="https://utgo.au/form" target="_blank">
              UTGO.AU/FORM
            </a>
            &nbsp;AND COMPLETE IT.
          </div>
          <div className="instruction-step">
            <div className="red-circle">2</div>
            COMPLETE THE QUIZ, GOOD LUCK!
          </div>
          <div className="instruction-step">
            <div className="red-circle">3</div>
            SCAN YOUR QR CODE TO CLAIM YOUR PRIZE!
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default Instructions;
