import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { ProgressBar, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Quiz = () => {
  const [progress, setProgress] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();
  const [isQuizComplete, setIsQuizComplete] = useState(false);

  useEffect(() => {
    fetch("https://utgo.uniqlo.1000headsdev.com/api/questions")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched data:", data);
        setQuestions(data.questions);
      })
      .catch((error) => console.error("Error fetching questions:", error));
  }, []);

  const handleAnswer = (optionId) => {
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers, optionId];

      const nextIndex = currentQuestionIndex + 1;

      if (nextIndex < questions.length) {
        setCurrentQuestionIndex(nextIndex);
        setProgress((nextIndex / questions.length) * 100);
      } else {
        setIsQuizComplete(true);
      }

      console.log(updatedAnswers);

      return updatedAnswers;
    });
  };

  useEffect(() => {
    if (isQuizComplete) {
      console.log("Final Answers:", answers);

      // Send data, get QR code. Send the QR code as a prop to below page.
      navigate("/mobile/result", { state: { answers } });
    }
  }, [isQuizComplete, answers, navigate]);

  if (questions.length != 4)
    return (
      <div className="vh-100 vw-100 d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-red">Loading...</h1>
      </div>
    );

  return (
    <div id="quiz">
      <Navbar />
      <ProgressBar now={progress} className="rounded-0 my-3" />
      <Container className="header-box">
        {questions.length > 0 && (
          <h2>{questions[currentQuestionIndex].statement}</h2>
        )}
      </Container>
      {questions.length > 0 && (
        <QuestionGrid
          question={questions[currentQuestionIndex]}
          handleAnswer={handleAnswer}
        />
      )}
      <Footer />
    </div>
  );
};

const QuestionGrid = ({ question, handleAnswer }) => {
  if (!question || !question.sets) {
    return <div>Loading question...</div>;
  }

  const options = question.sets.map((set) => {
    const randOption = Math.floor(Math.random() * set.options.length);
    const option = set.options[randOption];
    return {
      id_dichotomy: set.id_dichotomy,
      option: option,
    };
  });

  const rows = [];
  for (let i = 0; i < options.length; i += 2) {
    rows.push(options.slice(i, i + 2));
  }

  return (
    <Container fluid>
      {rows.map((rowOptions, rowIndex) => (
        <Row key={rowIndex} className="g-1">
          {rowOptions.map(({ option }) => (
            <button
              xs={6}
              key={option.id}
              onClick={() => handleAnswer(option.id)}
              className="col choice-item p-3"
            >
              <p>{option.ending_positive}</p>
            </button>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default Quiz;
