import React, { useState, useRef, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Idle = () => {
  const rewards = [
    "capcom",
    "zelda",
    "disney",
    "hokusai",
    "kenshi",
    "kpj",
    "kpj_2",
  ];

  const [screen, setScreen] = useState("scan_idle");
  const [reward, setReward] = useState(
    rewards[Math.floor(Math.random() * rewards.length)]
  );
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleBlur = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    const inputValue = inputRef.current.value;
    console.log(inputValue);
    triggerAnimation();
  };

  const triggerAnimation = () => {
    setScreen(reward);
  };

  const triggerError = () => {
    setScreen("scan_error");
  };

  const triggerRepeat = () => {
    setScreen("scan_repeat");
  };

  const handleVideoEnd = () => {
    setScreen("scan_idle");
  };

  const refreshReward = () => {
    setReward(rewards[Math.floor(Math.random() * rewards.length)]);
    console.log("NEW REWARD: " + reward);
  };

  window.triggerAnimation = triggerAnimation;
  window.triggerError = triggerError;
  window.refreshReward = refreshReward;
  window.triggerRepeat = triggerRepeat;

  return (
    <div id="idle" className="vh-100 vw-100 overflow-hidden">
      <Navbar absolute monitor />
      <video
        className="position-absolute top-0"
        autoPlay
        loop={screen === "scan_idle"}
        muted
        src={`/assets/screens/${screen}.mp4`}
        onEnded={screen === "scan_idle" ? null : handleVideoEnd}
      ></video>

      <Footer monitor />
      <form onSubmit={submitForm}>
        <input ref={inputRef} onBlur={handleBlur} />
      </form>
    </div>
  );
};

export default Idle;

function oldIdle() {
  <div className="h-100 d-flex align-items-center justify-content-center flex-column">
    <div id="idle-text">
      <h2>WHAT</h2>
      <h1>
        UT
        <br />
        RU<span className="small">?</span>
      </h1>
    </div>

    <div id="scan-prompt" className="border py-4 px-5 text-center">
      <h3 className="m-0">
        {hasScanned ? "SORRY, ALREADY SCANNED" : "SCAN QR CODE"}
      </h3>
    </div>
  </div>;
}
