import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/main.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Idle from "./pages/Idle";
import Reward from "./pages/Reward";
import UserForm from "./pages/UserForm";
import Quiz from "./pages/Quiz";
import Result from "./pages/Result";
import Instructions from "./pages/Instructions";
import Admin from "./pages/Admin";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Default Page */}
        <Route path="*" element={<Instructions />} />

        {/* Admin Page */}
        <Route path="/admin/*" element={<Admin />} />

        {/* Monitor Page */}
        <Route path="/monitor/*" element={<Idle />} />

        {/* Mobile Pages */}
        <Route path="/mobile/*" element={<UserForm />} />
        <Route path="/mobile/quiz" element={<Quiz />} />
        <Route path="/mobile/result" element={<Result />} />
      </Routes>
    </BrowserRouter>
  );
}
