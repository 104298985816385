import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";

const Result = () => {
  return (
    <div id="result">
      <Navbar />

      <Container>
        <div className="header-box my-4">
          <h1>Take the QR Code inside the UTGO</h1>
        </div>

        <div
          id="qr-code"
          className="border border-secondary p-5 d-flex align-items-center justify-content-center"
        >
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
            width="250px"
          />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default Result;
