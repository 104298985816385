import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Container, Table, Button, Modal, Form, Alert } from "react-bootstrap";

const Admin = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [email, setEmail] = useState("");

  const entries = [
    { fullname: "John Doe", timestamp: "2023-10-15 10:00" },
    { fullname: "Jane Smith", timestamp: "2023-10-15 12:30" },
  ];

  const handlePasswordSubmit = async () => {
    try {
      const response = await fetch(
        "https://utgo.uniqlo.1000headsdev.com/api/auth",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ password }),
        }
      );

      const data = await response.json();
      if (data.isAuthenticated) {
        setIsAuthenticated(true);
        setErrorMessage("");
      } else {
        setErrorMessage(data.error || "Incorrect password");
      }
    } catch (error) {
      console.error("Authentication error:", error);
      setErrorMessage("An error occurred while authenticating.");
    }
  };

  const handleShowModal = (type, entry) => {
    setModalType(type);
    setSelectedEntry(entry);
    setShowModal(true);
  };

  const handleSendCoupon = () => {
    alert(`Coupon sent to ${email}`);
    setShowModal(false);
  };

  return (
    <div id="instructions">
      <Navbar />
      <Container className="mt-5">
        {!isAuthenticated ? (
          <div className="text-center">
            <h3>Enter Password to Access Admin Page</h3>
            <Form.Control
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-3"
            />
            <Button
              onClick={handlePasswordSubmit}
              className="mt-3 bg-red border-0"
            >
              Submit
            </Button>
            {errorMessage && (
              <Alert variant="danger" className="mt-3">
                {errorMessage}
              </Alert>
            )}
          </div>
        ) : (
          <div>
            <h3>Admin Entries</h3>
            <Table striped bordered hover className="mt-3">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Timestamp</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.fullname}</td>
                    <td>{entry.timestamp}</td>
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleShowModal("qr", entry)}
                        className="me-2"
                      >
                        View QR Code
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => handleShowModal("coupon", entry)}
                        className="me-2"
                      >
                        View Coupon
                      </Button>
                      <Button
                        variant="success"
                        onClick={() => handleShowModal("send", entry)}
                      >
                        Send Coupon
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </Container>
      <Footer />

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "qr" && "View QR Code"}
            {modalType === "coupon" && "View Coupon"}
            {modalType === "send" && "Send Coupon"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "qr" && (
            <img src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg" />
          )}
          {modalType === "coupon" && <p>Coupon details will go here.</p>}
          {modalType === "send" && (
            <Form>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={handleSendCoupon}
                className="mt-3 bg-red border-0"
              >
                Send
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Admin;
